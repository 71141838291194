import { Map } from 'immutable';
import { isNil } from 'ramda';
import { format } from 'date-fns';
import {
  draft,
  invoiced,
  requestInvoice,
  rework,
  accepted
} from '@/presenters/sidebars/PurchaseRequestResponsesSidebar/status';
import { ProcurementStatus } from '@/utils/procurement';

const EMPTY = {
  Array: [],
  Object: {}
};

export const getRequestStatus = (request) => request.get('status');

export const responseEndDate = (request) => request.get('responseEndDate');

export const isPublished = (request) => Boolean(request.get('isPublished'));

export const responseEndTime = (request) =>
  new Date(responseEndDate(request)).getUTCHours();

export const isEndRespondTime = (request) => {
  const endDate = responseEndDate(request);
  return endDate ? new Date().getTime() > new Date(endDate).getTime() : false;
};

export const isFromThatCompany = (request, company) =>
  request.get('companyId') === company.get('id');

export const havePermissionToRespond = (
  fullPermissionToCustomers,
  fullPermissionToCustomersOrders
) => fullPermissionToCustomers && fullPermissionToCustomersOrders;

export const hasResponded = (request) =>
  !isNil(request.getIn(['responded', 'status']));

export const getStatusForSupplier = (request) => {
  const status = request.getIn(['responded', 'status']);
  if (!status) return 'none';
  return status === 'sent' ? 'sent-as-supplier' : status;
};

export const isOnReworkStatus = (request) =>
  [rework].includes(getStatusForSupplier(request));

export const isStatusDraft = (request) =>
  [draft, rework].includes(getStatusForSupplier(request));

export const isCanRespond = (request) =>
  ((!hasResponded(request) || isStatusDraft(request)) &&
    !isEndRespondTime(request)) ||
  (hasResponded(request) &&
    isOnReworkStatus(request) &&
    isEndRespondTime(request));

export const canDeleteResponse = (request) =>
  getStatusForSupplier(request) === draft;
/**
 * @param {Map} request
 * @returns {number}
 */
export const getQuestionnaireId = (request) =>
  request.get('accreditationQuestionnaireId');

/**
 * @param {Map} request
 * @returns {boolean}
 */
export const accreditationRequired = (request) =>
  request.get('accreditationRequired');

/**
 * @param {Map} request
 * @returns {boolean}
 */
export const isRequestScheduled = (request) =>
  request.get('status') === ProcurementStatus.Scheduled;

/**
 * @param {Map} request
 * @returns {string}
 */
export const getFormatedResponseStartDate = (request) => {
  const responseStartDate = request.get('responseStartDate');
  if (!responseStartDate) {
    return '';
  }

  return format(new Date(responseStartDate), 'dd.MM.yyyy, HH:mm');
};

/**
 * @param {import('../entities/PurchaseRequest').PurchaseRequest} request
 * @returns {boolean}
 */
// eslint-disable-next-line arrow-body-style
export const isCurrenciesAllowed = (request) => {
  let currencies;

  if ('get' in request) {
    currencies = request.get('currencies')?.toJS();
  } else {
    currencies = request.currencies;
  }
  return (currencies || []).some((currency) => !currency.base);
};

/**
 * @returns {import('@/components/CurrencyEditor').Currency[]}
 */
// eslint-disable-next-line arrow-body-style
export const getCurrencies = (request) => {
  return request.get('currencies')?.toJS();
};

export const showControlButtons = (request, havePermissionToUpdate = false) => {
  if (!havePermissionToUpdate) return false;

  if (
    getRequestStatus(request) === ProcurementStatus.ReceptionCompleted ||
    getRequestStatus(request) === ProcurementStatus.Completed ||
    getRequestStatus(request) === ProcurementStatus.Canceled
  ) {
    return (
      !isNil(request.getIn(['responded', 'status'])) &&
      request.getIn(['responded', 'status']) !== draft
    );
  }

  return true;
};

export const isProcurementFinalized = (request) => {
  if (isNil(request)) {
    return false;
  }

  const requestObject = request instanceof Map ? request.toJS() : request;

  return [ProcurementStatus.Canceled, ProcurementStatus.Completed].includes(
    requestObject.status
  );
};

export function hasNextStage(request) {
  if (isNil(request)) {
    return false;
  }

  const requestObject = request instanceof Map ? request.toJS() : request;

  return !!requestObject.childRequestId;
}

export const responseCountAndUnitsError = (responseErrors) =>
  Object.values(responseErrors).some((responseError) => Boolean(responseError));

export const allowCustomerFeedback = (request, response, feedbackId) => {
  if (isNil(request) || isNil(response)) {
    return false;
  }

  const requestObject = request instanceof Map ? request.toJS() : request;
  const responseObject = response instanceof Map ? response.toJS() : response;

  if (
    requestObject.status === ProcurementStatus.Completed &&
    (responseObject.status === invoiced ||
      responseObject.status === requestInvoice ||
      responseObject.status === accepted) &&
    isNil(feedbackId)
  ) {
    return true;
  }

  return false;
};

export const greaterThanDraft = (request) =>
  !hasResponded(request) || !isStatusDraft(request);

export const getResponseId = (request) => request.getIn(['responded', 'id']);

export const getRespondTextByRequest = (request) =>
  request.getIn(['responded', 'text']) || '';

export const getRespondComment = (request) =>
  request.getIn(['responded', 'comment']) || '';

export const getLinksByRequest = (request) =>
  request.hasIn(['responded', 'links'])
    ? request.getIn(['responded', 'links']).toJS()
    : [];

export const getFilesByRequest = (request) =>
  request.hasIn(['responded', 'files'])
    ? request.getIn(['responded', 'files']).toJS()
    : [];

const prepareInitProducts = (product) => ({
  ...product,
  initPrice: product?.price || '',
  price: ''
});

export const getInitProductsByRequest = (request) =>
  request.get('products')?.toJS() || EMPTY.Array;

export const getCommentByRequest = (request) =>
  request.hasIn(['responded', 'text'])
    ? request.getIn(['responded', 'text'])
    : '';

export const getProductsByRequest = (request) =>
  request.hasIn(['responded', 'products'])
    ? request.getIn(['responded', 'products']).toJS()
    : getInitProductsByRequest(request).map(prepareInitProducts) || [];

export const getNonPriceCriteria = (request) =>
  request.get('nonPriceCriteria')?.toJS() || [];

// NOTE: Old relize
// const isCanRespond =
// fullPermissionToCustomers &&
// fullPermissionToCustomersOrders &&
// !isFromThatCompany &&
// (!request.get('responded') ||
//   (request.get('suppliers') && !request.get('suppliers').find(i => i.get('companyId') === company.get('id')))) &&
// !isEndRespondTime(responseEndDate);
