import pino from 'pino';
import { logsSocket } from '@/storage/logs.storage';

interface ErrorMessage {
  type: 'Error';
  msg: string;
  stack: string;
}

function isErrorMessage(message: any): message is ErrorMessage {
  return (
    message &&
    typeof message === 'object' &&
    message.type === 'Error' &&
    typeof message.msg === 'string' &&
    typeof message.stack === 'string'
  );
}

export const logger = pino({
  browser: {
    transmit: {
      send(level, logEvent) {
        const { messages, ts } = logEvent;

        const [message] = messages;

        logsSocket.init();

        if (isErrorMessage(message)) {
          logsSocket.emit('message', {
            timestamp: new Date(ts).toISOString(),
            level,
            message: message.msg,
            metadata: {
              url: window.location.href,
              stack: message.stack
            }
          });
        } else if (typeof message === 'object') {
          logsSocket.emit('message', {
            timestamp: new Date(ts).toISOString(),
            level,
            message: JSON.stringify(message, null, 2),
            metadata: {
              url: window.location.href,
              user_agent: window.navigator.userAgent
            }
          });
        } else {
          logsSocket.emit('message', {
            timestamp: new Date(ts).toISOString(),
            level,
            message,
            metadata: {
              url: window.location.href,
              user_agent: window.navigator.userAgent
            }
          });
        }
      }
    }
  }
});
