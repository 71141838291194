import { Navigate, Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useParams } from '@/hooks';

import {
  PublicQualificationApplication,
  AppliedSuccessfully
} from '@/pages/qualification';

import * as storeGetters from '@/storeGetters';

const NewApplicationPage = () => {
  const { companyId } = useParams();

  return <Navigate to={`/qualification/${companyId}/application/new`} />;
};

const AuthorizedRoutes = () => (
  <Routes>
    <Route path="/:companyId/application" element={<NewApplicationPage />} />
  </Routes>
);

const NotAuthorizedRoutes = () => (
  <Routes>
    <Route
      path="/:companyId/application"
      element={<PublicQualificationApplication />}
    />
    <Route path="/success" element={<AppliedSuccessfully />} />
  </Routes>
);

export const PublicQualificationRouter = () => {
  const isAuth = useSelector(storeGetters.isAuth);
  if (!isAuth) return <NotAuthorizedRoutes />;

  return <AuthorizedRoutes />;
};
