import { searchQuery } from '@/utils/searchQuery';
import request from '../../../../lib/request';

// TODO: типизировать и перенести в соответствующий сервис
export const changeMuted = (endpoint, isMuted) =>
  request.post('/api/push/change-muted', { endpoint, isMuted });
export const pushSubscribe = (subscription, subscriptionKey) =>
  request.post('/api/push/subscribe', { subscription, subscriptionKey });
export const pushUnsubscribe = (subscription, subscriptionKey) =>
  request.post('/api/push/unsubscribe', {
    endpoint: subscription.endpoint,
    subscriptionKey
  });
export const pushRenew = (expiredSubscriptionEndpoint, subscription) =>
  request.post('/api/push/renew', {
    expiredSubscriptionEndpoint,
    subscription
  });
export const getDeviceSubscriptionSettings = async (endpoint) =>
  request.get(`/api/push/device-settings${searchQuery.generate({ endpoint })}`);
