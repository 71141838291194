import { ClipboardEventHandler, KeyboardEventHandler, ReactNode } from 'react';
import { IMask } from 'react-imask';
import { StandardProps } from '../common.types';

enum Mask {
  Money = 'money'
}

export interface MaskInputProps {
  mask: IMask.AnyMaskedOptions['mask'] | Mask;
  prepare?: IMask.AnyMaskedOptions['prepare'];
  validate?: IMask.AnyMaskedOptions['validate'];
  commit?: IMask.AnyMaskedOptions['commit'];
  overwrite?: IMask.AnyMaskedOptions['overwrite'];
  lazy?: IMask.MaskedPattern['lazy'];
  blocks?: IMask.MaskedPattern['blocks'];
  pattern?: IMask.MaskedDate['pattern'];
  format?: IMask.MaskedDate['format'];
  parse?: IMask.MaskedDate['parse'];
  min?: IMask.MaskedDate['min'] | IMask.MaskedNumber['min'];
  max?: IMask.MaskedDate['max'] | IMask.MaskedNumber['max'];
  autofix?: IMask.MaskedRange['autofix'] | IMask.MaskedDateOptions['autofix'];
  scale?: IMask.MaskedNumber['scale'];
}

export interface InputProps extends MaskInputProps, StandardProps {
  label?: string;
  value?: string;
  onBlur?: () => void;
  onFocus?: () => void;
  disabled?: boolean;
  defaultValue?: string;
  onChange: (value: string) => void;
  valid?: boolean;
  placeholder?: string;
  name?: string;
  errorText?: string;
  onPaste?: ClipboardEventHandler<HTMLDivElement>;
  onKeyDown?: KeyboardEventHandler<HTMLDivElement>;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  completed?: boolean;
  required?: boolean;
  labelClassName?: string;
}
