import { StandardProps } from '@/components/common.types';
import { FocusEvent, HTMLInputTypeAttribute, ReactNode } from 'react';

export interface InputProps extends StandardProps {
  id?: string;
  value?: string;
  defaultValue?: string;
  onChange: (value: string) => void;
  valid?: boolean;
  disabled?: boolean;
  type?: HTMLInputTypeAttribute;
  placeholder?: string;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  autocompleteOn?: boolean;
  autocompleteOnlyByFocus?: boolean;
  name?: string;
  label?: string;
  labelEndAdornment?: ReactNode;
  labelDefinition?: string | ReactNode;
  required?: boolean;
  containerClassName?: string;
  labelClassName?: string;
  errorText?: string;
}

export const AUTOCOMPLETE = {
  On: 'on',
  Off: 'new-password'
} as const;

export type Autocomplete = typeof AUTOCOMPLETE[keyof typeof AUTOCOMPLETE];

export interface AutocompleteProps {
  autocompleteOn: boolean;
  autocompleteOnlyByFocus: boolean;
}

export interface AutocompleteActions {
  onFocus: (event: FocusEvent<HTMLElement>) => void;
  onBlur: (event: FocusEvent<HTMLElement>) => void;
}
