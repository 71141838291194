import { memo, useEffect } from 'react';
import { useIMask, IMask } from 'react-imask';
import { TextField, TextFieldClasses } from '@mui/material';
import cn from 'classnames';
import { useId } from '@/hooks';
import { Typography } from '@/components/Typography';
import { InputLabel } from '@/components/InputLabel';
import { InputProps, MaskInputProps } from './maskedInput.types';

import styles from './maskedInput.module.scss';

const textFieldClasses: Partial<TextFieldClasses> = {
  root: styles.root
};

class NullableMaskedNumber extends IMask.MaskedNumber {
  // @ts-ignore
  get typedValue() {
    // @ts-ignore
    return this.unmaskedValue !== ''
      ? // @ts-ignore
        super.typedValue
      : null;
  }

  set typedValue(num: number) {
    // @ts-ignore
    super.typedValue = num;
  }
}

const mask = (options: MaskInputProps) => {
  switch (options.mask) {
    case Number:
      return new NullableMaskedNumber({
        // @ts-ignore
        mask: Number,
        scale: 2,
        padFractionalZeros: false,
        normalizeZeros: false,
        radix: '.',
        mapToRadix: [','],
        // @ts-ignore
        min: 0,
        ...options
      });
    case 'money':
      return new NullableMaskedNumber({
        // @ts-ignore
        mask: Number,
        scale: 2,
        padFractionalZeros: false,
        normalizeZeros: false,
        radix: ',',
        mapToRadix: ['.'],
        // @ts-ignore
        min: 0,
        ...options
      });
    default:
      return options;
  }
};

function MaskedInputComponent({
  id: idOverride,
  className,
  label,
  name,
  onChange,
  defaultValue,
  value,
  onBlur,
  onFocus,
  disabled = false,
  valid = true,
  errorText,
  placeholder,
  onPaste,
  onKeyDown,
  completed = false,
  startAdornment,
  endAdornment,
  required = false,
  labelClassName,
  ...maskOptions
}: InputProps) {
  const id = useId(idOverride);

  const maskInputOpt: MaskInputProps = {
    mask: maskOptions.mask,
    prepare: maskOptions.prepare,
    validate: maskOptions.validate,
    commit: maskOptions.commit,
    autofix: maskOptions.autofix,
    lazy: maskOptions.lazy,
    blocks: maskOptions.blocks,
    pattern: maskOptions.pattern,
    format: maskOptions.format,
    parse: maskOptions.parse,
    min: maskOptions.min,
    max: maskOptions.max,
    scale: maskOptions.scale
  };

  if (maskOptions.overwrite) {
    maskInputOpt.overwrite = maskOptions.overwrite;
  }

  const { ref, setUnmaskedValue } = useIMask(
    mask(maskInputOpt) as IMask.AnyMaskedOptions,
    {
      onAccept: (val) => !completed && onChange(val),
      onComplete: (val) => completed && onChange(val)
    }
  );

  useEffect(
    () => setUnmaskedValue(value ? value.toString() : ''),
    [value, setUnmaskedValue]
  );

  return (
    <div className={className}>
      {label && (
        <InputLabel
          className={labelClassName}
          text={label}
          required={required}
          htmlFor={id}
        />
      )}
      <TextField
        name={name}
        inputRef={ref}
        classes={textFieldClasses}
        id={id}
        className={cn(className, styles.background)}
        type="text"
        value={value}
        defaultValue={defaultValue}
        data-testid="masked-input"
        placeholder={placeholder}
        disabled={disabled}
        error={!valid}
        onBlur={onBlur}
        onFocus={onFocus}
        onPaste={onPaste}
        onKeyDown={onKeyDown}
        InputProps={{
          startAdornment,
          endAdornment
        }}
      />

      {!valid && errorText && (
        <Typography variant="caption" className={styles.errorMessage}>
          {errorText}
        </Typography>
      )}
    </div>
  );
}

export const MaskedInput = memo(MaskedInputComponent);
MaskedInput.displayName = 'MaskedInput';
