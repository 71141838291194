export enum ProcurementType {
  regular = 'Regular',
  regularWithConfirmationRequest = 'RegularWithConfirmation',
  auction = 'Auction',
  auctionWithConfirmation = 'AuctionWithConfirmation'
}

export enum ProcurementStatus {
  Draft = 'draft',
  ReceivingResponses = 'receiving_responses',
  ReceptionCompleted = 'reception_completed',
  OnIntroduction = 'on_introduction',
  OnApproval = 'on_approval',
  DenialOfApproval = 'denial_of_approval',
  ForPublication = 'for_publication',
  Completed = 'completed',
  Canceled = 'canceled',
  Scheduled = 'scheduled',
  UnderModeration = 'under_moderation',
  ModerationFailed = 'moderation_failed',
  Agreed = 'agreed',
  Notified = 'notified',
  OnRework = 'on_rework'
}

export enum ExecutorsAction {
  Pending = 'pending',
  Resolve = 'resolve',
  Reject = 'reject',
  Rework = 'rework'
}
