import { ReactNode, useState } from 'react';
import { WithClassName } from '@/components/common.types';
import { ButtonIcon } from '../ButtonIcon';
import styles from './CollapsibleArea.module.scss';

export interface BtnCollapseProps {
  isCollapsed: boolean;
  onClick: () => void;
}

export function BtnCollapse({ isCollapsed, onClick }: BtnCollapseProps) {
  return (
    <ButtonIcon
      iconName={isCollapsed ? 'arrow-down' : 'arrow-up'}
      onClick={onClick}
      className={styles.button}
    />
  );
}

export interface CollapsibleAreaProps extends WithClassName {
  isCollapsed: boolean;
  visibleContent: ReactNode;
  collapsibleContent: ReactNode;
}

export function CollapsibleArea({
  isCollapsed,
  className,
  visibleContent,
  collapsibleContent
}: CollapsibleAreaProps) {
  const [collapsed, setCollapsed] = useState(isCollapsed);
  const handleCollapse = () => {
    setCollapsed((prevState) => !prevState);
  };

  return (
    <div className={className}>
      <div className={styles.collapsibleArea}>
        <div className={styles.visibleHeader}>
          <div className={styles.visibleContent}>{visibleContent}</div>
          <BtnCollapse onClick={handleCollapse} isCollapsed={collapsed} />
        </div>
        {!collapsed && (
          <div className={styles.collapsibleContent}>{collapsibleContent}</div>
        )}
      </div>
    </div>
  );
}
